import React, { useEffect, useState } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { postAPI } from "../../../api/apiCallMethods";
import { useDispatch, useSelector } from "react-redux";
import { showAdminUserDetails, getAdminUserDetails } from "../../../features/admin-users/adminUserDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import userRoleVar from "../../../utilities/global/userRole";


const UserDetail = (props) => {
    const { id, hide } = props;

    const [Adminshow, setAdminShow] = useState(false);

    const [EditAdminshow, setEditAdminshow] = useState(false);
    const handleEditAdminShow = () => setEditAdminshow(true);

    const [disable, setDisable] = useState('disabled');
    const [actionBtn, setActionBtn] = useState("d-none");

    const handleEditAdminClose = () => {
        console.log("click");
        setDisable("disabled");
        // setEditAdminshow(false)
        hide();
    }

    const handleDisable = () => {
        setDisable("");
        setActionBtn("");
    }

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [sortType, setSortType] = useState(undefined)
    const [orderBy, setOrderBy] = useState(undefined)
    const [filterType, setfilterType] = useState(undefined)
    const [filterValue, setfilterValue] = useState(undefined)

    const userRoleVarObject = userRoleVar();

    const params = {
        id
    }

    const [admincreatedAt, setAdminCreatedAt] = useState();
    const [adminEmail, setAdminEmail] = useState();
    const [adminIsLocked, setAdminIsLocked] = useState();
    const [adminName, setAdminName] = useState();
    const [adminPhone, setAdminPhone] = useState();
    const [adminRoleId, setAdminRoleId] = useState();
    const [adminUpdatedAt, setAdminUpdatedAt] = useState();
    const [adminUserType, setAdminUserType] = useState();

    const dispatch = useDispatch();
    const adminUserDetailsSlice = useSelector(getAdminUserDetails)
    const adminUserDetails = adminUserDetailsSlice?.adminUserDetails?.data;
    console.log("userdata", adminUserDetails);

    // setAdminCreatedAt(adminUserDetails?.createdAt);
    // setAdminEmail(adminUserDetails?.email);
    // setAdminIsLocked(adminUserDetails?.isLocked);
    // setAdminName(adminUserDetails?.name);
    // setAdminPhone(adminUserDetails?.phone);
    // setAdminRoleId(adminUserDetails?.roleId);
    // setAdminUpdatedAt(adminUserDetails?.updatedAt);
    // setAdminUserType(adminUserDetails?.userType);

    useEffect(() => {
        (async () => {
            await dispatch(showAdminUserDetails(params))
        })()
    }, [id]);

    // console.log("Running in the component Here are the Admin UsersDetail Slice....", adminUserDetailsSlice);
    // console.log("Running in the component Here are the Admin UsersDetail....", adminUserDetails);

    const [formData, setFormData] = useState({
        currentStatus: '',
        userName: adminUserDetails?.name,
        userPhone: adminUserDetails?.phone,
        userRoles: adminUserDetails?.roleId,

    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        // console.log(id, value);
        setFormData({ ...formData, [id]: value });
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log ("data",  props.id, formData.userName, formData.userEmail, formData.userPhone, formData.userRoles, formData.currentStatus)

        try {
            // Make a POST request to your API endpoint
            let resp = await postAPI(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_USER_DETAILS_EDIT, {
                id: props.id,
                name: formData.userName,
                email: formData.userEmail,
                phone: formData.userPhone,
                roleId: formData.userRoles,
                isLocked: "0"
            });
            // console.log('API Response:', resp.data.code);

            // Close the modal after successful submission
            if (resp.data.code === 400) {
                // setFormMessage(resp.data.message);
            } else {
                hide();
            }
        } catch (error) {
            console.error('Error:', error);
        }

    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Admin User Details</Modal.Title>
                <div className="d-flex justify-content-end">
                    <Button variant="link" className="editAdminUser" onClick={handleDisable}>Edit Profile</Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col lg="6">
                            <Row>
                                <Col lg="4" >
                                    <div className="dataBox mb-3">
                                        <h4>Created On</h4>
                                        <div className="dateTime">
                                            <h5>{formatDate(adminUserDetails?.createdAt)}</h5>
                                            <span className="time">{formatTime(adminUserDetails?.createdAt)}</span>
                                            {/* <h5>11/10/2023</h5>
                                            <span className="time">17:55:33</span> */}
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4">
                                    <div className="dataBox mb-3">
                                        <h4>User ID</h4>
                                        <div className="dateTime">
                                            <h5>{adminUserDetails?.id}</h5>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg="4">
                                    <div className="dataBox mb-3">
                                        <h4>Last Modified</h4>
                                        <div className="dateTime">
                                            <h5>{formatDate(adminUserDetails?.updatedAt)}</h5>
                                            <span className="time">{formatTime(adminUserDetails?.updatedAt)}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg="6">
                            <Form.Group as={Row} className="align-items-center  mb-3" controlId="currentStatus">
                                <Form.Label column lg={4} className="text-center">Current Status:</Form.Label>
                                <Col lg={8}>
                                    {/* <div className="select-dv"> */}
                                    <div className={`select-dv ${adminUserDetails?.isLocked === 1 ? "banned" : "active"}`}>
                                        <Form.Select defaultValue={adminUserDetails?.isLocked === "1" ? "DeActive" : "Active"} onChange={handleInputChange} disabled={disable}>
                                            <option value={0}>Active</option>
                                            <option value={1}>DeActive</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Form.Group>
                        </Col>

                        <Col lg="4">
                            <Form.Group className="mb-3" controlId="userName">
                                <Form.Label>User Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter user name" onChange={handleInputChange} defaultValue={adminUserDetails?.name} disabled={disable} />
                            </Form.Group>
                        </Col>

                        <Col lg="4">
                            <Form.Group className="mb-3" controlId="userEmail">
                                <Form.Label>User Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter user email" onChange={handleInputChange} defaultValue={adminUserDetails?.email} disabled={disable} />
                            </Form.Group>
                        </Col>

                        <Col lg="4">
                            <Form.Group className="mb-3" controlId="userPhone">
                                <Form.Label>User Phone No.</Form.Label>
                                <Form.Control type="tel" placeholder="Enter User Phone No." onChange={handleInputChange} defaultValue={adminUserDetails?.phone} disabled={disable} />
                            </Form.Group>
                        </Col>

                        <Col lg="4" className="mb-4">
                            <Form.Group as={Col} controlId="userRoles">
                                <Form.Label>Roles</Form.Label>
                                {/* 
                                <Form.Select 
                                    {
                                        adminUserDetails?.roleId === "1" ? 
                                            <>
                                                    defaultValue={userRoleVarObject.key0}
                                                    disabled={disable} onChange={handleInputChange}
                                            </>
                                        : adminUserDetails?.roleId === "2" ? 
                                            <>
                                                    defaultValue={userRoleVarObject.key1}
                                                    disabled={disable} onChange={handleInputChange}
                                            </>
                                        : adminUserDetails?.roleId === "3" ? 
                                            <>
                                                    defaultValue={userRoleVarObject.key2}
                                                    disabled={disable} onChange={handleInputChange}
                                            </>
                                        : null
                                    }
                                >
                                    <option value={1}>{userRoleVarObject.key0}</option>
                                    <option value={2}>{userRoleVarObject.key1}</option>
                                    <option value={3}>{userRoleVarObject.key2}</option>
                                </Form.Select> */}
                                <Form.Select
                                    defaultValue={adminUserDetails?.roleId === "1" ? "Super Admin" : adminUserDetails?.roleId === "2" ? "Admin" : adminUserDetails?.roleId === "3" ? "User" : "User"}
                                    // defaultValue={adminUserDetails?.userType}
                                    disabled={disable}
                                    onChange={handleInputChange}
                                >
                                    <option >Super Admin</option>
                                    <option >Admin</option>
                                    <option >User</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        {/* {adminUserDetails?.roleId} */}

                        <Col lg="12">
                            <div className={`btnGroup ${actionBtn}`}>
                                <Button variant="secondary"
                                    onClick={handleEditAdminClose}
                                    className="me-3">
                                    Cancel
                                </Button>

                                <Button variant="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </>
    );
};

export default UserDetail;
