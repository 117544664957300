
import React, { useEffect, useState } from "react";

import "../../../assets/css/dashboard/dashboard.css"

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { useDispatch, useSelector } from "react-redux";

import { showCancelledApplicationDetails, getCancelledApplicationDetails } from "../../../features/applications-management/cancelledApplicationDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";




const CancelledApplicationDetail = (props) => {

    const { id } = props;
    // const [id, setId] = useState(5)
    const dispatch = useDispatch();

    const cancelledApplicationDetailsSlice = useSelector(getCancelledApplicationDetails)
    const cancelledApplicationDetails = cancelledApplicationDetailsSlice?.cancelledApplicationDetails;
    const fundingDetails = cancelledApplicationDetails?.fundingDetails;

    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showCancelledApplicationDetails(params));
        })()
    }, []);
    console.log("This is the id in cancelled loan application details", id);
    // console.log("Here are the Cancelled Applications Details Slice", cancelledApplicationDetailsSlice);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cancelled Loan Application Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    {cancelledApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                    {!cancelledApplicationDetailsSlice?.loading && cancelledApplicationDetailsSlice?.error ? <div>Error: {cancelledApplicationDetailsSlice?.error}</div> : null}
                    {!cancelledApplicationDetailsSlice?.loading && cancelledApplicationDetails ? (
                        <Row className="mb-4">
                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Loan Application ID</h4>
                                    <h5>{id ? id : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Borrower ID</h4>
                                    <h5>{cancelledApplicationDetails?.borrowerIdNumber ? cancelledApplicationDetails?.borrowerIdNumber : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Duration</h4>
                                    {
                                        cancelledApplicationDetails?.duration
                                            ? <h5>{cancelledApplicationDetails?.duration} Months</h5>
                                            : <h5>N/A</h5>
                                    }
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Profit Rate</h4>
                                    <h5>{cancelledApplicationDetails?.profitRate ? cancelledApplicationDetails?.profitRate : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Cancellation Reason</h4>
                                    <h5>{cancelledApplicationDetails?.cancellationReason ? cancelledApplicationDetails?.cancellationReason : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Fee Refunded Amount</h4>
                                    {
                                        cancelledApplicationDetails?.feeRefundAmount ?
                                            <>
                                                <h5>SAR {cancelledApplicationDetails?.feeRefundAmount}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Created on</h4>
                                    <div className="dateTime">
                                        <h5>{cancelledApplicationDetails?.createdOnDate ? formatDate(cancelledApplicationDetails?.createdOnDate) : "N/A"}</h5>
                                        <span className="time">{cancelledApplicationDetails?.createdOnDate ? formatTime(cancelledApplicationDetails?.createdOnDate) : ""}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Req Status</h4>
                                    <h5>{cancelledApplicationDetails?.reqStatus ? cancelledApplicationDetails?.reqStatus : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>No. of Installments</h4>
                                    <h5>{cancelledApplicationDetails?.noOfInstallments ? cancelledApplicationDetails?.noOfInstallments : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Funded</h4>
                                    <h5>{cancelledApplicationDetails?.funded ? cancelledApplicationDetails?.funded : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Cancelled By</h4>
                                    <h5>{cancelledApplicationDetails?.cancelledBy ? cancelledApplicationDetails?.cancelledBy : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Refund Ref No.</h4>
                                    <h5>{cancelledApplicationDetails?.refundRefNo ? cancelledApplicationDetails?.refundRefNo : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Expiry Date</h4>
                                    <div className="dateTime">
                                        <h5>{cancelledApplicationDetails?.applicationExpiryDate ? formatDate(cancelledApplicationDetails?.applicationExpiryDate) : "N/A"}</h5>
                                        <span className="time">{cancelledApplicationDetails?.applicationExpiryDate ? formatTime(cancelledApplicationDetails?.applicationExpiryDate) : ""}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Loan Amount</h4>
                                    {
                                        cancelledApplicationDetails?.loanAmount ?
                                            <>
                                                <h5>SAR {cancelledApplicationDetails?.loanAmount}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Per Installment</h4>
                                    {
                                        cancelledApplicationDetails?.perInstallmentAmount ?
                                            <>
                                                <h5>SAR {cancelledApplicationDetails?.perInstallmentAmount}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Funded Amount</h4>
                                    {
                                        cancelledApplicationDetails?.fundedAmount ?
                                            <>
                                                <h5>SAR {cancelledApplicationDetails?.fundedAmount}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Fee Refund Status</h4>
                                    {
                                        cancelledApplicationDetails?.feeRefundStatus ?
                                            <>
                                                <h5>
                                                    <button className="btn btn-success">{cancelledApplicationDetails?.feeRefundStatus}</button>
                                                </h5>
                                            </>
                                            :
                                            <><h5>N/A</h5></>
                                    }
                                </div>
                            </Col>

                            <Col lg="2">
                                <div className="popupDv">
                                    <h4>Refunded By</h4>
                                    <h5>{cancelledApplicationDetails?.refundedBy ? cancelledApplicationDetails?.refundedBy : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Borrower Name</h4>
                                    <h5>{cancelledApplicationDetails?.borrowerName ? cancelledApplicationDetails?.borrowerName : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Loan Reason</h4>
                                    <h5>{cancelledApplicationDetails?.loanReason ? cancelledApplicationDetails?.loanReason : "N/A"}</h5>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Total Repay Amount</h4>
                                    {
                                        cancelledApplicationDetails?.totalRepayAmount ?
                                            <>
                                                <h5>SAR {cancelledApplicationDetails?.totalRepayAmount}</h5>
                                            </>
                                            :
                                            <>
                                                <h5>N/A</h5>
                                            </>
                                    }
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Cancellation Date</h4>
                                    <div className="dateTime">
                                        <h5>{cancelledApplicationDetails?.cancellationDate ? formatDate(cancelledApplicationDetails?.cancellationDate) : "N/A"}</h5>
                                        <span className="time">{cancelledApplicationDetails?.cancellationDate ? formatTime(cancelledApplicationDetails?.cancellationDate) : ""}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="2" className="mb-2">
                                <div className="popupDv">
                                    <h4>Fee Refunded Date</h4>
                                    <div className="dateTime">
                                        <h5>{cancelledApplicationDetails?.feeRefundDate ? formatDate(cancelledApplicationDetails?.feeRefundDate) : "N/A"}</h5>
                                        <span className="time">{cancelledApplicationDetails?.feeRefundDate ? formatTime(cancelledApplicationDetails?.feeRefundDate) : ""}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )
                        : null
                    }


                    <div className="accountSummary p-0 mb-3">
                        <h2 className="mb-3">Funding Details</h2>
                    </div>

                    <div className="records activeLoanApp">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ width: '100px', justifyContent: "center" }}>
                                        <div className="labelIco" style={{ justifyContent: "center" }}>
                                            <span className="headTitle">Sr.</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Funding Date</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Investor ID</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Investor Name</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Payment Ref No</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Amount<br />Funded</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Refunded</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Refund Date</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Refunded<br /> Amount</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Refund<br /> Payment Ref.</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {cancelledApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                                {!cancelledApplicationDetailsSlice?.loading && cancelledApplicationDetailsSlice?.error ? <div>Error: {cancelledApplicationDetailsSlice?.error}</div> : null}
                                {!cancelledApplicationDetailsSlice?.loading && fundingDetails?.length ? (

                                    fundingDetails.map(detail =>
                                    (

                                        <tr>
                                            <td align="center">
                                                <div className="serialNo">
                                                    <span>{detail?.sNo ? detail?.sNo : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dateTime sep">
                                                    <span className="date">{detail?.fundingDate ? formatDate(detail?.fundingDate) : "N/A"} </span><br />
                                                    <span className="time">{detail?.fundingDate ? formatTime(detail?.fundingDate) : ""}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="investorId">
                                                    <span>{detail?.investorId ? detail?.investorId : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="investorName">
                                                    <span>{detail?.investorName ? detail?.investorName : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refNo">
                                                    <span>{detail?.paymentRefNo ? detail?.paymentRefNo : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amountFunded" style={{ 'white-space': 'nowrap' }}>
                                                    {
                                                        detail?.amountFunded ?
                                                            <>
                                                                <span>SAR {formattedNumber(detail?.amountFunded)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <h5>N/A</h5>
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refunded status">
                                                    <button className={`btn ${detail?.refundStatus === "Success" ? "btn-success" : "btn-warning text-white"}`}>{detail?.refundStatus}</button>

                                                </div>
                                            </td>
                                            <td>
                                                <div className="dateTime sep">
                                                    <span className="date">{detail?.refundDate ? formatDate(detail?.refundDate) : "N/A"}</span><br />
                                                    <span className="time">{detail?.refundDate ? formatTime(detail?.refundDate) : ""}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refundedAmount" style={{ 'white-space': 'nowrap' }}>
                                                    {
                                                        detail?.refundedAmount ?
                                                            <>
                                                                <span>SAR {formattedNumber(detail?.refundedAmount)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <h5>N/A</h5>
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refNo">
                                                    <span>{detail?.refundPaymentRefNo ? detail?.refundPaymentRefNo : "N/A"}</span>
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                    )
                                )
                                    : null
                                }

                            </tbody>
                        </Table>
                        {!cancelledApplicationDetailsSlice?.loading && !fundingDetails?.length && !cancelledApplicationDetailsSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </>


    );
}

export default CancelledApplicationDetail;
