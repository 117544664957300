import React, { useEffect, useState } from "react";
import "../../../assets/css/dashboard/dashboard.css"

import Accordion from 'react-bootstrap/Accordion';
// import { useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom';
import HEADER from "../../../component/header/header";
import BREADCRUMB from "../../../component/breadcrumb/breadcrumb";
import COUNTER from "../../../component/admin-users/user-role/counter";
import SIDEBAR from "../../../component/sidebar/sidebar";

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import UserDetail from "./user-detail";

import { useDispatch, useSelector } from "react-redux";
import { listAdminUsers, getAdminUsersList } from "../../../features/admin-users/listAdminUsersSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";
import userRoleVar from "../../../utilities/global/userRole";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../../api/middleware/axiosInterceptors";
import useAuth from "../../../hooks/permission-hook";

const ALLWALLETS = () => {
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [sortType, setSortType] = useState(undefined)
    const [orderBy, setOrderBy] = useState(undefined)
    const [filterType, setfilterType] = useState(undefined)
    const [filterValue, setfilterValue] = useState(undefined)

    const userRoleVarObject = userRoleVar();

    const data = {
        limit,
        page,
        orderBy,
        sortType,
        filterType,
        filterValue
    }
    const dispatch = useDispatch();
    const adminUsersSlice = useSelector(getAdminUsersList)
    const adminUsers = adminUsersSlice?.adminUsers?.data?.managerList;

    // console.log("userlist ", adminUsersSlice);

    useEffect(() => {
        (async () => {
            await dispatch(listAdminUsers(data))
        })()
    }, [limit, page, orderBy, sortType, filterType, filterValue]
    );

    const [perPage, setPerPage] = useState()
    const [total, setTotal] = useState()
    const [totalPages, setTotalPages] = useState()

    useEffect(() => {
        // Update state values when borrowerSlice changes
        setTotal(adminUsersSlice?.adminUsers?.data?.total);
        setTotalPages(adminUsersSlice?.adminUsers?.data?.totalPages);
    }, [adminUsersSlice]);

    const [Adminshow, setAdminShow] = useState(false);

    const handleAdminClose = () => setAdminShow(false);

    const handleAdminShow = () => setAdminShow(true);

    const [EditAdminshow, setEditAdminshow] = useState(false);

    const [showModals, setShowModals] = useState(Array(10).fill(false));

    const handleEditAdminShow = (index) => {
        const updateModals = [...showModals];
        updateModals[index] = true;
        setShowModals(updateModals)
        setEditAdminshow(true)
    }

    const handleEditAdminClose = (index) => {
        const updateModals = [...showModals];
        updateModals[index] = false;
        setShowModals(updateModals)
        setDisable("disabled")
        setEditAdminshow(false)
    }

    // const handleEditAdminShow = () => setEditAdminshow(true);

    const [disable, setDisable] = useState('disabled');

    // user roles
    const [useRoleData, setuseRoleData] = useState([]);
    const [useRoleId, setuseRoleId] = useState();

    // useEffect(() => {
    //     // Retrieve data from localStorage
    //     const storedUserData = localStorage.getItem("userRoles");
    //     const storedUserId = localStorage.getItem("userRoleId");

    //     setuseRoleId(storedUserId);

    //     console.log(storedUserData);

    //     if (storedUserData) {
    //         // Parse the stored data
    //         const parsedUserData = JSON.parse(storedUserData);
    //         // Find the object with the specified role ID
    //         const filteredRole = parsedUserData.find(role => role.roleId === storedUserId);
    //         console.log("1");
    //         if (filteredRole) {
    //             // If a role with the specified ID is found, set the state with that role object
    //             setuseRoleData(filteredRole);
    //             console.log("2");
    //             // Set loading to false after useEffect completes

    //         } else {
    //             // If no role with the specified ID is found
    //             console.log("No role data found for the specified role ID.");
    //             console.log("3");
    //         }
    //     }

    // }, []);

    useEffect(() => {
        // Retrieve data from localStorage
        const token = localStorage.getItem("userToken");
        const decoded = jwtDecode(token);
        const storedUserData = localStorage.getItem("userRoles");
        const storedUserId = decoded.roleId;

        setuseRoleId(decoded.roleId);

        if (storedUserData) {
            // Parse the stored data
            const parsedUserData = JSON.parse(storedUserData);
            //console.log("parsedUserData======", parsedUserData)

            // Filter the parsed data based on roleId
            const filteredRoles = parsedUserData.filter(role => role.roleId == storedUserId);
            if (filteredRoles.length > 0) {
                // If there are roles with the specified roleId
                const filterRoleData = filteredRoles[0];
                setuseRoleData(filterRoleData); // Set the role data
            } else {
                // If there are no roles with the specified roleId
                console.log("No role data found for the specified roleId.");
            }
        }
    }, [useRoleId]);

    // const handleEditAdminClose = () => {
    //     setDisable("disabled")
    //     setEditAdminshow(false)
    // }
    const handleDisable = () => {
        setDisable("");
    }

    const handlePageNumber = (e) => {
        const value = e.currentTarget.value

        if (!isNaN(Number(value)) && Number(value) >= 1 && Number(value) <= totalPages) {
            setPage(value);
        }
    }

    const handlPrevClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) >= 1
            //  && Number(value) <= totalPages
        ) {
            if (Number(value) === 1) {
                setPage(1)
            } else {
                setPage(state => Number(state) - 1)
            }
        }
    }

    const handlNextClick = () => {
        const value = page;
        if (!isNaN(Number(value)) && Number(value) < totalPages && Number(value) >= 1) {
            setPage(state => Number(state) + 1)
            // if (Number(value) === totalPages) {
            //   setPage(totalPages)
            // }
        }
    }

    const reload = () => {
        if (page !== 1 || sortType !== "desc" || orderBy !== "createdAt" || filterType !== undefined || filterValue !== undefined) {
            setPage(1);
            setSortType("desc");
            setOrderBy("createdAt");
            setfilterType(undefined);
            setfilterValue(undefined);
        } else {
            dispatch(listAdminUsers(data))
        }
    }

    const handleSearchChange = (e) => {
        e.preventDefault();

        const idNumber = e.currentTarget.value

        if (idNumber.length <= 9 && !isNaN(Number(idNumber))) {
            // if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
            setfilterType("id");
            setfilterValue(idNumber);
        }
        if (idNumber.length === 0) {
            setfilterType(undefined);
            setfilterValue(undefined);
        }

        setSortType(undefined);
        setOrderBy(undefined);
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const idNumber = e.currentTarget.elements.serachInput.value
        // console.log(idNumber)
        if (idNumber.length === 9 && !isNaN(Number(idNumber))) {
            setfilterType("id");
            setfilterValue(idNumber);
            setSortType(undefined);
            setOrderBy(undefined);
        }

    }

    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [role, setRole] = useState('2');

    const handleCreateUserSubmit = async (e) => {
        e.preventDefault();
        const data = {

        }
        try {
            const response = await axiosInstance({
                method: "POST",
                headers: {
                    apiid: process.env.REACT_APP_APIID,
                    apikey: process.env.REACT_APP_APIKEY,
                },
                url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADMIN_CREATE_USER,
                data: {
                    name: userName,
                    email: userEmail,
                    phone: userPhone,
                    userType: "admin",
                    roleId: role,
                    application: process.env.REACT_APP_APPLICATION_NAME,
                    applicationVersion: process.env.REACT_APP_APPLICATION_VERSION,
                }
            });

            if (response.data.code === 200) {
                setAdminShow(false);
                setUserName('');
                setUserEmail('');
                setUserPhone('');
                setRole('2');
            }
            if (response.data.code === 400) {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error(error.message);
        }



        console.log("User Created, now sending the api call");
        setAdminShow(false);
    }

    const handleCancel = async (e) => {
        setAdminShow(false);
        setUserName('');
        setUserEmail('');
        setUserPhone('');
        setRole('2');

    }
    const [permissions, checkPermission]  = useAuth();

    return (
        <>
            <div className="dashoboard">
                <SIDEBAR />

                <div className="component">
                    <HEADER />
                    <BREADCRUMB />

                    {
                         checkPermission({subject:"admin user management", action:"read"})  ?
                            <COUNTER />
                            :
                            null
                    }

                    <div className="content">
                        {
                            checkPermission({subject:"admin user management", action:"update"}) ?
                                <div className="action-dv my-4">
                                    <div className="d-flex justify-content-end">
                                        <Button className="btn-theme-primary" onClick={handleAdminShow}>
                                            <i>
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z" fill="white" />
                                                </svg>
                                            </i>
                                            <span>Add New User</span>
                                        </Button>
                                    </div>
                                </div>
                                :
                                null
                        }


                        <Modal show={Adminshow} size="lg" aria-labelledby="contained-modal-title-vcenter" className="adminUserModal" centered onHide={handleAdminClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Admin User </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* <Form onSubmit={handleCreateUserSubmit}>
                                    <Row>
                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                                <Form.Label>User Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter user name" />
                                            </Form.Group>
                                        </Col>

                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                                <Form.Label>User Email</Form.Label>
                                                <Form.Control type="email" placeholder="Enter user email" />
                                            </Form.Group>
                                        </Col>

                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                <Form.Label>User Phone No.</Form.Label>
                                                <Form.Control type="tel" placeholder="Enter User Phone No." />
                                            </Form.Group>
                                        </Col>

                                        <Col lg="4" className="mb-4">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <Form.Label>Roles</Form.Label>
                                                <Form.Select defaultValue="Administrator">
                                                    <option>Choose...</option>
                                                    <option>Administrator</option>
                                                    <option>Moderater</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col lg="12">
                                            <div className="btnGroup">
                                                <Button variant="secondary" onClick={handleAdminClose} className="me-3">
                                                    Cancel
                                                </Button>

                                                <Button variant="primary" type="submit">
                                                    Create
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form> */}
                                <Form onSubmit={handleCreateUserSubmit}>
                                    <Row>
                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formGroupUserName">
                                                <Form.Label>User Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter user name"
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                                <Form.Label>User Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter user email"
                                                    value={userEmail}
                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg="4">
                                            <Form.Group className="mb-3" controlId="formGroupPhone">
                                                <Form.Label>User Phone No.</Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    placeholder="Enter User Phone No."
                                                    value={userPhone}
                                                    onChange={(e) => setUserPhone(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col lg="4" className="mb-4">
                                            <Form.Group as={Col} controlId="formGridState">
                                                <Form.Label>Roles</Form.Label>
                                                <Form.Select value={role} onChange={(e) => setRole(e.target.value)}>
                                                    <option value="2">Operation Admin</option>
                                                    <option value="3">Operation User</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col lg="12">
                                            <div className="btnGroup">
                                                <Button variant="secondary" className="me-3" onClick={handleCancel}>
                                                    Cancel
                                                </Button>

                                                <Button variant="primary" type="submit">
                                                    Create
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        <div className="optionsBox">
                            <Row>
                                <Col lg="6">
                                    <div className="searchBox">
                                        <Form className="searchForm" onSubmit={handleSearchSubmit}>
                                            {/* <Form.Control className="" id="serachInput" placeholder="Search by User ID, User Name" /> */}
                                            <Form.Control
                                                className=""
                                                type="text"
                                                id="serachInput"
                                                placeholder="Search by User ID"
                                                // maxLength={9}
                                                onChange={handleSearchChange}

                                            />
                                            <Button type="submit" className="">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16.5 16.5L11.5 11.5M1.5 7.33333C1.5 8.09938 1.65088 8.85792 1.94404 9.56565C2.23719 10.2734 2.66687 10.9164 3.20854 11.4581C3.75022 11.9998 4.39328 12.4295 5.10101 12.7226C5.80875 13.0158 6.56729 13.1667 7.33333 13.1667C8.09938 13.1667 8.85792 13.0158 9.56565 12.7226C10.2734 12.4295 10.9164 11.9998 11.4581 11.4581C11.9998 10.9164 12.4295 10.2734 12.7226 9.56565C13.0158 8.85792 13.1667 8.09938 13.1667 7.33333C13.1667 6.56729 13.0158 5.80875 12.7226 5.10101C12.4295 4.39328 11.9998 3.75022 11.4581 3.20854C10.9164 2.66687 10.2734 2.23719 9.56565 1.94404C8.85792 1.65088 8.09938 1.5 7.33333 1.5C6.56729 1.5 5.80875 1.65088 5.10101 1.94404C4.39328 2.23719 3.75022 2.66687 3.20854 3.20854C2.66687 3.75022 2.23719 4.39328 1.94404 5.10101C1.65088 5.80875 1.5 6.56729 1.5 7.33333Z" stroke="url(#paint0_linear_15_1117)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_15_1117" x1="1.46638" y1="1.79751" x2="15.8646" y2="16.1974" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#236092" />
                                                            <stop offset="1" stopColor="#270089" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>

                                <Col lg="6">
                                    <div className="pagination">
                                        <span className="totalRecord me-3">{total ? total : "N/A"} Records</span>
                                        <Form.Control
                                            className=""
                                            id="pageCount"
                                            placeholder=""
                                            value={page}
                                            onChange={handlePageNumber}
                                        />

                                        <span className="sep">- </span>
                                        <span className="showRecord">{totalPages ? totalPages : "N/A"} &nbsp;&nbsp;&nbsp;</span>

                                        <span className="prePage" style={{ cursor: "pointer" }} onClick={handlPrevClick}>
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="#696969" />
                                                <g clipPath="url(#clip0_0_1)">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.95496 12.205C9.74429 12.4159 9.62595 12.7018 9.62595 13C9.62595 13.2981 9.74429 13.584 9.95496 13.795L14.197 18.0385C14.408 18.2494 14.6942 18.3679 14.9926 18.3678C15.291 18.3677 15.5771 18.2491 15.7881 18.0381C15.999 17.827 16.1175 17.5408 16.1174 17.2424C16.1174 16.944 15.9988 16.6579 15.7877 16.447L12.3407 13L15.7877 9.55295C15.9927 9.34087 16.1063 9.05675 16.1038 8.76177C16.1014 8.4668 15.9832 8.18458 15.7748 7.97589C15.5663 7.76721 15.2842 7.64876 14.9892 7.64606C14.6942 7.64336 14.41 7.75662 14.1977 7.96145L9.95421 12.2042L9.95496 12.205Z" fill="#696969" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_0_1">
                                                        <rect width="18" height="18" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 22 22)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>

                                        <span className="nextPage" style={{ cursor: "pointer" }} onClick={handlNextClick}>
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" stroke="#696969" />
                                                <g clipPath="url(#clip0_0_1)">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.045 12.205C16.2557 12.4159 16.374 12.7018 16.374 13C16.374 13.2981 16.2557 13.584 16.045 13.795L11.803 18.0385C11.592 18.2494 11.3058 18.3679 11.0074 18.3678C10.709 18.3677 10.4229 18.2491 10.2119 18.0381C10.001 17.827 9.8825 17.5408 9.88257 17.2424C9.88264 16.944 10.0012 16.6579 10.2123 16.447L13.6593 13L10.2123 9.55295C10.0073 9.34087 9.89373 9.05675 9.89615 8.76177C9.89858 8.4668 10.0168 8.18458 10.2252 7.97589C10.4337 7.76721 10.7158 7.64876 11.0108 7.64606C11.3058 7.64336 11.59 7.75662 11.8023 7.96145L16.0458 12.2042L16.045 12.205Z" fill="#696969" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_0_1">
                                                        <rect width="18" height="18" fill="white" transform="translate(4 22) rotate(-90)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>

                                        <span className="reloadList" style={{ cursor: "pointer" }} onClick={reload}>
                                            <svg fill="#696969" height="12" width="12" viewBox="0 0 489.711 489.711">
                                                <path d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13 c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13 c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1 c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"></path>
                                                <path d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7 c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8 c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1 c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4 C456.056,357.911,481.656,274.811,462.456,195.511z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="records activeLoanApp">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Create Date</span>
                                                <span className="icon">
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z" fill="#270089" />
                                                        <path d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z" fill="#270089" />
                                                        <path d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z" fill="#270089" />
                                                        <path opacity="0.35" d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z" fill="#270089" />
                                                        <path opacity="0.35" d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z" fill="#270089" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">User ID</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">User Name</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Roles</span>

                                                <Dropdown align={{ lg: 'end' }} className="statusDropdown">
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        <span className="icon">
                                                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.579 0C15.2539 0 1.58822 0 1.26316 0C0.565897 0 0 0.565897 0 1.26316C0 1.96043 0.565897 2.52632 1.26316 2.52632C1.58822 2.52632 15.2539 2.52632 15.579 2.52632C16.2763 2.52632 16.8422 1.96043 16.8422 1.26316C16.8422 0.565897 16.2763 0 15.579 0Z" fill="#270089" />
                                                                <path opacity="0.35" d="M1.68433 2.52637L4.72349 6.17354C5.48055 7.08133 5.89487 8.2266 5.89487 9.40807V13.9588C5.89487 14.7824 6.49024 15.4847 7.30203 15.6203L9.47635 15.9824C10.2469 16.1113 10.9475 15.5176 10.9475 14.7369V9.40892C10.9475 8.2266 11.3618 7.08217 12.1189 6.17438L15.1581 2.52637H1.68433Z" fill="#270089" />
                                                            </svg>
                                                        </span>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/action-1">All</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">Moderator</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">Account Manager</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">Administrator</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Status</span>
                                                <span className="icon">
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z" fill="#270089" />
                                                        <path d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z" fill="#270089" />
                                                        <path d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z" fill="#270089" />
                                                        <path opacity="0.35" d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z" fill="#270089" />
                                                        <path opacity="0.35" d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z" fill="#270089" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle">Last Modified</span>
                                                <span className="icon">
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.2955 11.2C9.67869 11.2 9.36829 11.944 9.80269 12.3824L12.8875 15.4968C13.2803 15.8928 13.9203 15.8928 14.3131 15.4968L17.3979 12.3824C17.8323 11.944 17.5219 11.2 16.9051 11.2H10.2955Z" fill="#270089" />
                                                        <path d="M15.2002 12C15.2002 12 15.2002 6.95765 15.2002 6.40005C15.2002 5.51605 14.4842 4.80005 13.6002 4.80005C12.7162 4.80005 12.0002 5.51605 12.0002 6.40005C12.0002 6.95765 12.0002 12 12.0002 12H15.2002Z" fill="#270089" />
                                                        <path d="M13.6002 3.2C14.4839 3.2 15.2002 2.48366 15.2002 1.6C15.2002 0.716344 14.4839 0 13.6002 0C12.7166 0 12.0002 0.716344 12.0002 1.6C12.0002 2.48366 12.7166 3.2 13.6002 3.2Z" fill="#270089" />
                                                        <path opacity="0.35" d="M7.79779 3.6177L4.713 0.503299C4.32019 0.107299 3.6802 0.107299 3.2874 0.503299L0.202595 3.6177C-0.231805 4.0561 0.0785954 4.8001 0.695395 4.8001H2.4002V9.6001C2.4002 10.4841 3.1162 11.2001 4.0002 11.2001C4.88419 11.2001 5.60019 10.4841 5.60019 9.6001V4.8001H7.30499C7.92179 4.8001 8.23219 4.0561 7.79779 3.6177Z" fill="#270089" />
                                                        <path opacity="0.35" d="M4.00015 16C4.8838 16 5.60015 15.2837 5.60015 14.4C5.60015 13.5164 4.8838 12.8 4.00015 12.8C3.11649 12.8 2.40015 13.5164 2.40015 14.4C2.40015 15.2837 3.11649 16 4.00015 16Z" fill="#270089" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="labelIco">
                                                <span className="headTitle"> </span>
                                            </div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminUsersSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                                    {!adminUsersSlice?.loading && adminUsersSlice?.error ? <div>Error: {adminUsersSlice.error}</div> : null}
                                    {!adminUsersSlice?.loading && adminUsers?.length ? (
                                        adminUsers.map((adminUser, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="dateTime">
                                                        <span className="date">{formatDate(adminUser?.createdAt)}</span><br />
                                                        <span className="time">{formatDate(adminUser?.createdAt)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="walletIban">
                                                        <span>{adminUser?.id}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="userName">
                                                        <span>{adminUser?.name}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="roles">
                                                        {/* <span>{adminUser?.roleId}</span> */}
                                                        {
                                                            adminUser?.roleId === "1" ?
                                                                <p>{userRoleVarObject.key0}</p>
                                                                : adminUser?.roleId === "2" ?
                                                                    <p>{userRoleVarObject.key1}</p>
                                                                    : adminUser?.roleId === "3" ?
                                                                        <p>{userRoleVarObject.key2}</p>
                                                                        : null
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="status">
                                                        <span className="dot "></span>
                                                        <span>Active</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="dateTime">
                                                        <span className="date">{formatDate(adminUser?.updatedAt)}</span><br />
                                                        <span className="time">{formatDate(adminUser?.updatedAt)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        checkPermission({subject:"admin user management", action:"update"}) ?
                                                            <>
                                                                {/* <div className="action"> */}
                                                                {/* <Link to="/user-roles" className="btn btn-view">View</Link> */}
                                                                {/* <Button className="btn btn-view" variant="primary" onClick={handleEditAdminShow}>View</Button> */}

                                                                {/* <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />  */}
                                                                {/* </div> */}

                                                                <div className="action">
                                                                    <Button className="btn btn-view" variant="primary" onClick={() => handleEditAdminShow(index)}>View</Button>

                                                                    <MyVerticallyCenteredModal show={showModals[index]} onHide={() => handleEditAdminClose(index)} id={adminUser?.id} />

                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    )
                                        : null
                                    }

                                </tbody>
                            </Table>

                            {/* <Modal show={EditAdminshow} size="lg" aria-labelledby="contained-modal-title-vcenter" className="adminUserModal" centered onHide={handleEditAdminClose}>
                                <UserDetail />
                            </Modal> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function MyVerticallyCenteredModal(props) {
    const id = props.id

    return (
        // <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="adminUserModal" centered onHide={props.onHide} >
        //     <UserDetail id={id} />
        // </Modal>
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" className="adminUserModal" centered onHide={props.onHide}>
            <UserDetail id={id} hide={props.onHide} />
        </Modal>
    )
}

export default ALLWALLETS