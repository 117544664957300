
import React, { useEffect, useState } from "react";

import "../../../assets/css/dashboard/dashboard.css"

// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';


import { useDispatch, useSelector } from "react-redux";
import { showExpiredApplicationDetails, getExpiredApplicationDetails } from "../../../features/applications-management/expiredApplicationDetailsSlice";
import { formatDate, formatTime } from "../../../utilities/helper/formatDateTime";
import formattedNumber from "../../../utilities/helper/formatAmount/formatNumber";



const ExpiredApplicationDetail = (props) => {

    const { id } = props;
    // const [id, setId] = useState(5)

    const dispatch = useDispatch();
    const expiredApplicationDetailsSlice = useSelector(getExpiredApplicationDetails)
    const expiredApplicationDetails = expiredApplicationDetailsSlice?.expiredApplicationDetails;
    const fundingDetails = expiredApplicationDetails?.fundingDetails;

    // console.log("Here are the Expired Applications Details Slice", expiredApplicationDetailsSlice);
    // console.log("Here are the Expired Applications Details", expiredApplicationDetails);
    // console.log("This is the id in expired appilcation detail", id)


    const params = {
        id
    }
    useEffect(() => {
        (async () => {
            await dispatch(showExpiredApplicationDetails(params));
        })()
    }, []);


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Expired Loan Application Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <Row className="mb-4">
                        <Col lg="10">
                            {expiredApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                            {!expiredApplicationDetailsSlice?.loading && expiredApplicationDetailsSlice?.error ? <div>Error: {expiredApplicationDetailsSlice?.error}</div> : null}
                            {!expiredApplicationDetailsSlice?.loading && expiredApplicationDetails ? (
                                <Row>
                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Application ID</h4>
                                            <h5>{id ? id : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower ID</h4>
                                            <h5>{expiredApplicationDetails?.borrowerIdNumber ? expiredApplicationDetails?.borrowerIdNumber : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Duration</h4>
                                            {
                                                expiredApplicationDetails?.duration ?
                                                    <><h5>{expiredApplicationDetails?.duration} Months</h5></>
                                                    :
                                                    <><h5>N/A</h5></>
                                            }

                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Profit Rate</h4>
                                            <h5>{expiredApplicationDetails?.profitRate ? expiredApplicationDetails?.profitRate : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Created on</h4>
                                            <div className="dateTime">
                                                <h5>{expiredApplicationDetails?.createdOnDate ? formatDate(expiredApplicationDetails?.createdOnDate) : "N/A"}</h5>
                                                <span className="time">{expiredApplicationDetails?.createdOnDate ? formatTime(expiredApplicationDetails?.createdOnDate) : ""}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Req Status</h4>
                                            <h5>{expiredApplicationDetails?.reqStatus ? expiredApplicationDetails?.reqStatus : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>No. of Installments</h4>
                                            <h5>{expiredApplicationDetails?.noOfInstallments ? expiredApplicationDetails?.noOfInstallments : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Funded</h4>
                                            <h5>{expiredApplicationDetails?.funded ? expiredApplicationDetails?.funded : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Expired on</h4>
                                            <div className="dateTime">
                                                <h5>{expiredApplicationDetails?.expiredOnDate ? formatDate(expiredApplicationDetails?.expiredOnDate) : "N/A"}</h5>
                                                <span className="time">{expiredApplicationDetails?.expiredOnDate ? formatTime(expiredApplicationDetails?.expiredOnDate) : ""}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Amount</h4>
                                            {
                                                expiredApplicationDetails?.loanAmount ?
                                                    <>
                                                        <h5>SAR {formattedNumber(expiredApplicationDetails?.loanAmount)}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Per Installment</h4>
                                            {
                                                expiredApplicationDetails?.perInstallmentAmount ?
                                                    <>
                                                        <h5>SAR {formattedNumber(expiredApplicationDetails?.perInstallmentAmount)}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Funded Amount</h4>
                                            {
                                                expiredApplicationDetails?.fundedAmount ?
                                                    <>
                                                        <h5>SAR {formattedNumber(expiredApplicationDetails?.fundedAmount)}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Borrower Name</h4>
                                            <h5>{expiredApplicationDetails?.borrowerName ? expiredApplicationDetails?.borrowerName : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Loan Reason</h4>
                                            <h5>{expiredApplicationDetails?.loanReason ? expiredApplicationDetails?.loanReason : "N/A"}</h5>
                                        </div>
                                    </Col>

                                    <Col lg="3" className="mb-2">
                                        <div className="popupDv">
                                            <h4>Total Repay Amount</h4>
                                            {
                                                expiredApplicationDetails?.totalRepayAmount ?
                                                    <>
                                                        <h5>SAR {formattedNumber(expiredApplicationDetails?.totalRepayAmount)}</h5>
                                                    </>
                                                    :
                                                    <>
                                                        <h5>N/A</h5>
                                                    </>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            )
                                : null
                            }

                        </Col>
                    </Row>

                    <div className="accountSummary p-0 mb-3">
                        <h2 className="mb-3">Funding Details</h2>
                    </div>

                    <div className="records">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ width: '100px', justifyContent: "center" }}>
                                        <div className="labelIco">
                                            <span className="headTitle">Sn.</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Funding Date</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Investor ID</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Investor Name</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Payment Ref No</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Amount<br />Funded</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle" style={{ 'white-space': 'nowrap' }}>Refunded</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco" style={{ 'white-space': 'nowrap' }}>
                                            <span className="headTitle">Refund Date</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle">Refunded<br /> Amount</span>
                                        </div>
                                    </th>
                                    <th >
                                        <div className="labelIco">
                                            <span className="headTitle" style={{ 'white-space': 'nowrap' }}>Refund<br /> Payment Ref.</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {expiredApplicationDetailsSlice?.loading && <div className="notifyNoRecord">Loading...</div>}
                                {!expiredApplicationDetailsSlice?.loading && expiredApplicationDetailsSlice?.error ? <div>Error: {expiredApplicationDetailsSlice?.error}</div> : null}
                                {!expiredApplicationDetailsSlice?.loading && fundingDetails?.length ? (

                                    fundingDetails.map(detail =>
                                    (

                                        <tr>
                                            <td align="center">
                                                <div className="serialNo">
                                                    <span>{detail?.sNo ? detail?.sNo : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dateTime">
                                                    <span className="date">{detail?.fundingDate ? formatDate(detail?.fundingDate) : "N/A"}</span>&nbsp;
                                                    <span className="time">{detail?.fundingDate ? formatTime(detail?.fundingDate) : ""}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="investorId">
                                                    <span>{detail?.investorId ? detail?.investorId : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="investorName" style={{ 'white-space': 'nowrap' }}>
                                                    <span>{detail?.investorName ? detail?.investorName : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refNo">
                                                    <span>{detail?.paymentRefNo ? detail?.paymentRefNo : "N/A"}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="amountFunded" style={{ 'white-space': 'nowrap' }}>
                                                    {
                                                        detail?.amountFunded ?
                                                            <>
                                                                <span>SAR {formattedNumber(detail?.amountFunded)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span>N/A</span>
                                                            </>
                                                    }

                                                </div>
                                            </td>
                                            <td>
                                                <div className="refunded status">
                                                    <button className={`btn ${detail?.refundStatus === "Success" ? "btn-success" : "btn-danger"}`}>{detail?.refundStatus}</button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="dateTime">
                                                    <span className="date">{detail?.refundDate ? formatDate(detail?.refundDate) : "N/A"}</span>
                                                    <span className="time">{detail?.refundDate ? formatTime(detail?.refundDate) : ""}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refundedAmount">
                                                    {
                                                        detail?.refundedAmount ?
                                                            <>
                                                                <span>SAR {formattedNumber(detail?.refundedAmount)}</span>
                                                            </>
                                                            :
                                                            <>
                                                                <span>N/A</span>
                                                            </>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="refundedPaymentRef">
                                                    <span>{detail?.refundPaymentRefNo ? detail?.refundPaymentRefNo : "N/A"}</span>
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                    )
                                )
                                    : null
                                }

                            </tbody>
                        </Table>

                        {!expiredApplicationDetailsSlice?.loading && !fundingDetails?.length && !expiredApplicationDetailsSlice?.error && <><div className="notifyNoRecord">Data doesn't exist!</div></>}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </>


    );
}

export default ExpiredApplicationDetail;
